import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function userRatingList() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refRatesTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'title', sortable: false },
    { key: 'status', sortable: false },
    { key: 'createdAt', sortable: false },
    { key: 'updatedAt', sortable: false },
    { key: 'action', sortable: false },

  ]
  const perPage = ref(10)
  const totalRates = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const outOfStockFilter = ref(null)
  const activeFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refRatesTable.value
      ? refRatesTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRates.value,
    }
  })

  const refetchData = () => {
    refRatesTable.value.refresh()
  }
  const fetchRates = (ctx, callback) => {
    store
      .dispatch('rating/fetchRatings', {
        q: searchQuery.value === '' ? null : searchQuery.value,
        take: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const rates = response.body.data
        totalRates.value = response.body.meta.itemCount
        callback(rates)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching posts list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const timeout = ref(null)
  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      outOfStockFilter,
      activeFilter,
    ],
    () => {
      showTable.value = true
      clearTimeout(timeout)
      timeout.value = setTimeout(() => {
        refetchData()
        showTable.value = false
      }, 600)
    },
  )
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    timeout,
    showTable,
    fetchRates,
    tableColumns,
    perPage,
    currentPage,
    totalRates,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRatesTable,
    refetchData,
    // Extra
  }
}
