import { render, staticRenderFns } from "./RatingConfigUpdate.vue?vue&type=template&id=e566b032&"
import script from "./RatingConfigUpdate.vue?vue&type=script&lang=js&"
export * from "./RatingConfigUpdate.vue?vue&type=script&lang=js&"
import style0 from "./RatingConfigUpdate.vue?vue&type=style&index=0&id=e566b032&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports