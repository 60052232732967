<template>

  <div>
    <RatingConfigAddNew
      :is-add-new-rates-sidebar-active.sync="isAddNewRatingSideBar"
      @refetch-data="refreshTable"
    />
    <RatingConfigUpdate
      :is-update-rate-sidebar-active.sync="isUpdateRateSidebarActive"
      :data-rating="dataRate"
      @refetch-data="refreshTable"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                :disabled="!$can('rating:create')"
                :to="{ name: 'add-rating-config' }"
                variant="primary"
              >
                <span class="text-nowrap">Add rating</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refRatesTable"
          class="position-relative"
          :items="fetchRates"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: Title -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.title }}
              </b-link>
            </b-media>
          </template>

          <!-- Column: is Active -->
          <template #cell(status)="data">
            <span class="text-capitalize">
              <b-form-checkbox
                :disabled="!$can('general:update')"
                :checked="data.item.isActive"
                switch
                @change="switchActive(data.item)"
              />
            </span>
          </template>
          <!-- Column: createdAt -->
          <template #cell(createdAt)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.createdAt) }}
            </span>
          </template>
          <!-- Column: updatedAt -->
          <template #cell(updatedAt)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.updatedAt) }}
            </span>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-link
              v-if="$can('general:update')"
              :to="{ name: 'update-rating-config', params:{id: data.item.id} }"
            >
              Edit
            </b-link>
            <b-link
              v-if="$can('general:delete')"
              @click="deleteRate(data.item.id)"
            >
              Delete
            </b-link>
          </template>

        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRates"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BPagination, BOverlay, BFormCheckbox,
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import userRatingList from '@/views/ratingConfig/rating'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import RatingConfigAddNew from '@/components/ratingConfig/RatingConfigAddNew.vue'
import RatingConfigUpdate from '@/components/ratingConfig/RatingConfigUpdate.vue'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    vSelect,
    RatingConfigAddNew,
    RatingConfigUpdate,
    BFormCheckbox,
  },
  computed: {
    ...mapGetters('rating', ['optionsActive']),
  },
  methods: {
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 600)
    },
    switchActive(rate) {
      const payload = rate
      // eslint-disable-next-line prefer-destructuring
      payload.isActive = !rate.isActive
      store.dispatch('rating/updateRating',
        { id: rate.id, rate: payload })
        .then(() => {
          this.refreshTable()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update active success',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error update show',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    showEditBar(data) {
      this.dataRate = {
        title: data.title,
        status: {
          label: this.optionsActive.find(e => e.value === data.isActive).label,
          value: data.isActive,
        },
        id: data.id,
      }
      this.isUpdateRateSidebarActive = true
    },
    formatDate(time) {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    deleteRate(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this rating.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('rating/deleteRating', { id })
              .then(() => {
                this.refreshTable()
              })
          }
        })
    },
  },

  setup() {
    const dataRate = ref({})
    const isAddNewRatingSideBar = ref(false)
    const isUpdateRateSidebarActive = ref(false)
    const {
      timeout,
      showTable,
      fetchRates,
      tableColumns,
      perPage,
      currentPage,
      totalRates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRatesTable,
      refetchData,
    } = userRatingList()
    return {
      timeout,
      showTable,
      // Sidebar
      dataRate,
      isUpdateRateSidebarActive,
      isAddNewRatingSideBar,
      fetchRates,
      tableColumns,
      perPage,
      currentPage,
      totalRates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRatesTable,
      refetchData,

      // Filter
      avatarText,

      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
